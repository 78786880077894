import './App.css';
import { useEffect, useState } from 'react';

const riddles = [
  { id:1,  question: "Hello and welcome to the Barb n' Durr Treasured Hunt extravaganza. The riddles you solve will lead you to the locations of the secret words and phrases needed to move onto the next question. Are you ready to begin adventurer? (hint: rhymes with \"less\") ", answer: 'yes'},
  { id:2,  question: "Question 1: I lie dormant until awakened on top of a tabletop. I contain many tiles, yet no floors. I contain many letters, yet no words. Find me to discover the secret hehe...", answer: 'uraqt'},
  { id:3,  question: "Question 2: I was lost but then found, a new home to share my elegance. You see within me beauty as I bury my skeletons underneath. Find me to discover the secret hehe...", answer: 'uhmnão'},
  { id:4,  question: "Question 3: A floating nest in a tranquil space. In my embrace, the world seems bright to this child-bearing mother. I wonder if she still think about her kids? Find me to discover the secret hehe...", answer: 'littlebitchboy'},
  { id:5,  question: "Question 4: I am your unsung sidekick, a friend to lend a shoulder to. You may let me down at times, but I know you'll be there to pick me back up again. Find me to discover the secret hehe...", answer: 'iheartdarren'},
  { id:6,  question: "Question 5: I stand guarded and private, weathering whatever mother nature delivers. I serve my duty with fellow guards, but never share any information between them. Find me to discover the secret hehe...", answer: 'improudofyou'},
  { id:7,  question: "Question 6: I am a lost brother, my 7 siblings in Hocking Hills know not of my existence. I lounge in sun all day, only clouded by mortal instruments of pleasure. Find me to discover the secret hehe...(hint: you do have to leave the house adventurer, and this quest will not bring you back for a little while.)", answer: 'miloandfifi'},
  { id:8,  question: "Question 7: A slice of paradise for our furry child, where this wallflower can bloom. Before you can reach this oasis, one must traverse the rocky path...Find me to discover the secret hehe...", answer: 'imsogladimetu'},
  { id:9,  question: "Question 8: Congratulations on making it this far!! To celebrate, it is time for a sweetie treatie, head to the martial art of delectable drinks and popping pearls. Find me to discover the secret hehe...", answer: 'umakemesohappy'},
  { id:10,  question: "Question 9: Tick tok, timing is everything! Looks like next clue is at the the tip of fingers, but wait till 4pm to arrive at the next destination. Find me to discover the secret hehe...", answer: 'uaretheloveofmylife'},
  { id:11,  question: "Question 10: Head home, weary traveler, as you must prepare your final stretch of the journey. At home where we lie will hold the final secret which holds the time and place to get your marvelous treasure (Wait to answer)", answer: 'madisonavebeach6:50'},
  { id:12,  question: "The Final Question: Our life together has been a journey that I never want to end. I want to be with you for every hurdle you face and every achievement you earn. My life feels whole when I'm with you, you push me to be better than I was yesterday and I only hope that I do the same for you. The final question will come from Durr himself, not this silly little website", answer: 'yes'},
]
function App() {
  const storedIndex = parseInt(localStorage.getItem('riddleIndex'), 10);
  const [riddleIndex, setRiddleIndex] = useState(isNaN(storedIndex) ? 0 : storedIndex);
  const [userInput, setUserInput] = useState('')
  const [isCorrect, setIsCorrect] = useState(null);

  useEffect(() => {
    console.log('test', riddleIndex)
    localStorage.setItem('riddleIndex', riddleIndex.toString());
  }, [riddleIndex])

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const checkAnswer = () => {
    if(userInput.toLowerCase() === riddles[riddleIndex].answer) {
      setIsCorrect(true);
      setTimeout(() => {
        if (riddleIndex < riddles.length - 1) {
          setRiddleIndex(riddleIndex + 1);
        } else {
          alert("Congratulations! We're engaged!!!!!");
        }
        setIsCorrect(null);
        setUserInput('');
      }, 1000);
    } else {
      setIsCorrect(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      checkAnswer();
    }
  };

  const goBack = () => {
    if (riddleIndex > 0) {
      setRiddleIndex(riddleIndex - 1)
      setIsCorrect(null)
      setUserInput('')
    }
  }
  return (
    <div className='div'>
      <div className='hearts'>❤❤❤❤❤❤❤❤❤</div>
      <h1><span className='hearts'>❤</span> Barb n' Durr <span className='hearts'>❤</span></h1>
      <div className='hearts'>❤❤❤❤❤❤❤❤❤</div>
      <p key={riddles[riddleIndex].id}>{riddles[riddleIndex].question}</p>
      <input placeholder="Enter the secrets..." value={userInput} onChange={handleInputChange} onKeyDown={handleKeyDown} />
      <button onClick={checkAnswer}>Submit</button>
      {isCorrect && <p>Correct! Moving to the next riddle...</p>}
      {isCorrect === false && <p>Whoops! Try again!</p>}
      {riddleIndex > 0 && (
        <button onClick={goBack}>Previous Riddle</button>
      )}
    </div>
  );
}

export default App;
